<template>
  <div class="page-content" id="details" v-loading="isLoading">
    <!-- 主要内容 -->
    <div class="main detail-content-box">
      <div class="good-info">
        <!-- 左侧商品轮播图 -->
        <div class="carousel-box">
          <el-carousel
            :height="bannerHeight + 'px'"
            v-if="productDetails.imgList && productDetails.imgList.length > 1"
          >
            <el-carousel-item
              class="good-swiper"
              v-for="item in productDetails.imgList"
              :key="item"
              :height="bannerHeight + 'px'"
            >
              <img
                ref="bannerHeight"
                class="good-detail-img"
                :src="item"
                alt="商品图"
                @load="imgload"
              />
            </el-carousel-item>
          </el-carousel>
          <img
            ref="bannerHeight"
            class="good-detail-img"
            :src="productDetails.firstPic || defaultImg"
            alt=""
            @load="imgload"
            v-else
          />
          <div class="statisticImgUp">
            <el-statistic
              class="statistic-time"
              format="DD天HH时mm分钟ss秒"
              :value="remainder | handleTime"
              time-indices
              :value-style="{
                color: '#ffffff',
                fontSize: '17px',
                lineHeight: '35px',
              }"
            >
            </el-statistic>
          </div>
        </div>
        <!-- 左侧商品轮播图END -->

        <!-- 右侧内容区 -->
        <div class="content">
          <div class="good-tit">{{ productDetails.title }}</div>
          <p class="intro">{{ productDetails.intro }}</p>
          <!-- <div class="remainder">
            <el-statistic style="display: flex;
    flex-direction: row;
    margin-bottom: 15px;" format="DD天HH时mm分钟ss秒" :value="remainder | handleTime" time-indices title="倒计时：" :value-style="{color:'red'}">
              <template slot="suffix">
                <span style="font-size: 12px;">后结束</span>
              </template>
            </el-statistic>
          </div> -->
          <div class="subItem">
            <div class="itemTitle">类别</div>
            <div class="itemContent">
              <ul>
                <!-- :class="{selectActive:nexNumber === resIndex}" -->
                <li class="selectActive">
                  <span
                    >{{ productDetails.year }}
                    {{ productDetails.expansionSize }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- v-if="show" -->
          <!-- <div class="subItem" >
            <div class="itemTitle">时长</div>
            <div class="itemContent">
              <ul>
                <li  class="selectActive">
                  <el-badge :value="seckillGoods.discount !==0 ? seckillGoods.discount +'折':''" class="badgeItem">{{seckillGoods.timeLimitVal}}</el-badge>
                </li>
              </ul>
            </div>
          </div> -->
          <!-- <div class="subItem">
            <div class="itemTitle">支付方式</div>
            <div class="zhiFuItem">
              <div :class="['change-paytype',{current:payOrder.payType === 'wxpay'}]" @click="selectPayType('wxpay')">
                <div class="change-paytype-icon">
                  <img src="../assets/imgs/wxpay.png" class="paytype-icon" />
                </div>
                <div class="change-paytype-info">
                  <div class="change-paytype-label">微信支付</div>
                </div>
              </div>
              <div :class="[
              'change-paytype',
              { current: payOrder.payType === 'alipay' },
            ]" @click="selectPayType('alipay')">
                <div class="change-paytype-icon">
                  <img src="../assets/imgs/alipay.png" class="paytype-icon" />
                </div>
                <div class="change-paytype-info">
                  <div class="change-paytype-label">支付宝</div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="subItem" style="align-items: self-end">
            <div class="itemTitle">价格</div>
            <div class="price-info">
              <div class="sale-price">
                <div class="price-icon">¥</div>
                <div class="price-val">{{ productDetails.sellPrice }}</div>
              </div>
              <del class="price-old">¥{{ productDetails.originalPrice }}</del>
            </div>
          </div>

          <!-- 内容区底部按钮 -->
          <el-button
            class="shop-cart"
            v-if="!userInfo || (userInfo && userInfo.status !== 0)"
            :disabled="payBtnDisabled"
            @click="handleShop"
            >立即秒杀</el-button
          >
        </div>
      </div>
      <!-- 右侧内容区END -->
      <div class="good-info-item">
        <div class="good-item-list">
          <div class="good-item-tit">
            <span class="good-item-line"></span>商品详情
          </div>
          <div class="goodimg-box">
            <p v-html="particulars"></p>
          </div>
        </div>
        <!-- 这里是 简介页面 -->
        <!-- <el-tabs type="border-card" class="tabsDetail">
          <el-tab-pane label="商品详情">
            <div class="intro">
              <p>商品名称:{{productDetails.goodsName}}</p>
              <p>商品简介:{{ productDetails.goodsIntroduction }}</p>
            </div>
            <div>
              <p v-html="particulars"></p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="配置管理">配置管理</el-tab-pane>
          <el-tab-pane label="角色管理">角色管理</el-tab-pane>
          <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>
        </el-tabs> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { getSeckillGoods, createOrder } from '@/api/home'
import { getwxShare } from '@/utils/wexinShare'
export default {
  data() {
    return {
      remainder: '',
      type: '',
      defaultImg: require('@/assets/imgs/no-image.png'),
      bannerHeight: null,
      isLoading: true, //控制加载层
      se: '', //控制选中颜色改变
      nexNumber: '0',
      yearNumber: '0',
      show: false,
      showIndex: null,
      payPrice: '', //售卖价
      originalPrice: '', //原价
      preferentialPrice: '', //优惠
      dis: false, // 控制“加入购物车按钮是否可用”
      payBtnDisabled: false, //控制立即购买按钮重复提交
      productID: '', // 商品id
      productDetails: '', // 商品详细信息
      productPicture: '', // 商品图片
      timer: null, // 定时器
      codeUrl: '', //支付二维码地址
      orderId: '', //订单号
      particulars: '',
      spuSaleAttrList: [
        // { label: "年", value: "0" },
        // { label: "季度", value: "1" },
        // { label: "月", value: "2" }
      ],
      payOrder: {
        productId: '', //商品id
        payType: 'wxpay', //支付方式
        timeType: '',
        timeLimit: '',
        goodsId: '',
      },
      spuSaleAttrYearList: [],
      selectArr: [], // 存放被选中的值
      subIndex: [], //是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断
    }
  },
  filters: {
    handleTime(value) {
      // console.log(value);
      const date = new Date(value)
      const year = date.getFullYear()
      const month = '0' + (date.getMonth() + 1)
      const day = '0' + date.getDate()
      const hours = '0' + date.getHours()
      const minutes = '0' + date.getMinutes()
      const seconds = '0' + date.getSeconds()
      return new Date(`${year}/${month}/${day} ${hours}:${minutes}:${seconds}`)
    },
  },
  // 通过路由获取商品id
  activated() {},
  created() {
    const { productID } = this.$route.query
    this.productID = productID
    this.getSeckillGoods(productID)
  },
  computed: {
    // ...mapState({
    //   userInfo: (state) => state.user.user,
    // }),
    userInfo: {
      get() {
        return this.$store.state.user.user
      },
      set(value) {
        this.$store.commit('setUser', value)
      },
    },
    completeUrl() {
      const baseUrl = `${location.protocol}//${location.host}`;
      const query = this.$route.fullPath.split('?')[0]; // 获取除去查询参数部分的URL
      return `${baseUrl}${query}`;
    }
  },
  mounted() {
    this.imgload()
    this.initWeChatConfig()
    window.addEventListener('resize', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleScroll)
  },
  methods: {
    async initWeChatConfig() {
      var url = window.location.href.split('#')[0].split('?')[0];
      // const parsedURL = new URL(url);
      // const domain = parsedURL.origin;
      var goodsType = 1
      var params={
        url:url,
        goodsId:this.productID,
        goodsType:goodsType
      }
    const path ='#/goods/seckilldetails?productID=';
     getwxShare(params,url,goodsType,path,'')
    },
    handleScroll() {
      this.bannerHeight =
        this.$refs.bannerHeight[0].height > 560
          ? 480
          : this.$refs.bannerHeight[0].height
      const detailsContainer = document.querySelector('.el-carousel__container')
      if (detailsContainer) {
        detailsContainer.style.height = this.bannerHeight + 'px'
        this.imgload()
      }
    },
    imgload() {
      this.$nextTick(() => {
        if (this.$refs.bannerHeight) {
          this.bannerHeight = this.$refs.bannerHeight[0]
            ? this.$refs.bannerHeight[0].height > 560
              ? 480
              : this.$refs.bannerHeight[0].height
            : 480
        }
      })
    },
    ...mapActions(['unshiftShoppingCart', 'addShoppingCartNum']),
    // 获取秒杀商品详情
    async getSeckillGoods(seckillGoodsId) {
      this.isLoading = true
      const res = await getSeckillGoods(seckillGoodsId)
      if (res.code === 200) {
        this.remainder = res.data.seckillList.endTime // 目标日期的时间戳
        this.productDetails = res.data.seckillList
        this.particulars = this.repairRichText(
          res.data.seckillList.goodsDetails
        )
        this.isLoading = false
      }
    },
    /**
     * 修复富文本图片宽度
     * @description 解决图片宽高超出显示不全问题(让其自适应)
     * @param {String} html - 富文本
     * @return String
     */
    repairRichText(html) {
      // console.log(html);
      // 去掉<img>中style /width / height属性
      let newContent = html.replace(/<img[^>]*>/gi, (match) => {
        match = match
          .replace(/style="[^"]+"/gi, '')
          .replace(/style='[^']+'/gi, '')
        match = match
          .replace(/width="[^"]+"/gi, '')
          .replace(/width='[^']+'/gi, '')
        match = match
          .replace(/height="[^"]+"/gi, '')
          .replace(/height='[^']+'/gi, '')
        return match
      })
      // 修改所有style里的width属性为max-width:100%
      newContent = newContent.replace(/style="[^"]+"/gi, (match) => {
        match = match
          .replace(/width:[^;]+;/gi, 'max-width:100%;')
          .replace(/width:[^;]+;/gi, 'max-width:100%;')
        return match
      })
      // 去掉所有<br/>标签
      newContent = newContent.replace(/<br[^>]*\/>/gi, '')
      // img标签添加style属性：max-width:100%;height:auto
      newContent = newContent.replace(
        /<img/gi,
        '<img style="max-width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;"'
      )
      // return
      return newContent
    },
    //选择支付方式
    selectPayType(type) {
      // console.log("支付方式：" + type);
      this.payOrder.payType = type
      //this.$router.push({ path: '/order' })
    },
    // 判断是否在微信内置浏览器打开
    isWX() {
      var wx = window.navigator.userAgent.toLowerCase()
      console.log(wx)
      if (wx.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    // 立即购买
    async handleShop() {
      console.log(this.$store.getters.getUser)
      if (!this.$store.getters.getUser) {
        this.$store.dispatch('setShowLogin', true)
        return
      }
      //禁用按钮，防止重复提交
      this.payBtnDisabled = true
      const params = {
        goodsId: this.productDetails.goodsId,
        goodsName: this.productDetails.title,
        phoneNumber: this.$store.getters.getUser.mobile,
        sellType: '1',
        // mobilePay: this.$device.mobile ? 1 : 0
        mobilePay: this.isWX() == true ? 2 : this.$device.mobile ? 1 : 0,
        seckillId: this.productDetails.id,
      }
      const res = await createOrder(params)
      if (res.data.map.orderInfo.orderId) {
        this.payBtnDisabled = false
        this.$router.push({
          name: 'ConfirmOrder',
          query: {
            orderId: res.data.map.orderInfo.orderId,
            productType: res.data.map.orderInfo.productType,
            orderType: res.data.map.orderType,
            seckillId: this.productDetails.id,
          },
        })
      }
    },
    // 加入购物车
    addShoppingCart() {
      // 判断是否登录,没有登录则显示登录组件
      if (!this.$store.getters.getUser) {
        this.$store.dispatch('setShowLogin', true)
        return
      }
      this.$axios
        .post('/api/user/shoppingCart/addShoppingCart', {
          user_id: this.$store.getters.getUser.user_id,
          product_id: this.productID,
        })
        .then((res) => {
          switch (res.data.code) {
            case '001':
              // 新加入购物车成功
              this.unshiftShoppingCart(res.data.shoppingCartData[0])
              this.notifySucceed(res.data.msg)
              break
            case '002':
              // 该商品已经在购物车，数量+1
              this.addShoppingCartNum(this.productID)
              this.notifySucceed(res.data.msg)
              break
            case '003':
              // 商品数量达到限购数量
              this.dis = true
              this.notifyError(res.data.msg)
              break
            default:
              this.notifyError(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    addCollect() {
      // 判断是否登录,没有登录则显示登录组件
      if (!this.$store.getters.getUser) {
        this.$store.dispatch('setShowLogin', true)
        return
      }
      this.$axios
        .post('/api/user/collect/addCollect', {
          user_id: this.$store.getters.getUser.user_id,
          product_id: this.productID,
        })
        .then((res) => {
          if (res.data.code == '001') {
            // 添加收藏成功
            this.notifySucceed(res.data.msg)
          } else {
            // 添加收藏失败
            this.notifyError(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.page-content {
  margin-top: 10px;
}
.detail-content-box {
  width: 100%;
  min-height: 64vh;
  margin: 0 auto;
  .good-info {
    display: flex;
    background-color: #fff;
    padding: 40px;
    margin-bottom: 36px;
    margin-top: 40px;
    .carousel-box {
      // width: 45%;
      width: 38.5%;
      // height: 500px;
      height: 516px;
      margin-bottom: 40px;
      .good-detail-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .statisticImgUp {
        height: 40px;
        background-color: #fa8919;
        opacity: 0.8;
        text-align: center;
      }
    }
    .content {
      margin-left: 70px;
      width: 55%;
      position: relative;
      .good-tit {
        font-size: 35px;
        color: #333;
        font-weight: bold;
        margin-bottom: 5px;
        margin-top: 2px;
      }
      .intro {
        color: #666;
        padding-top: 10px;
        margin-bottom: 30px;
      }
      .subItem {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        // background-color: red;
        .itemTitle {
          width: 15%;
          font-size: 16px;
          color: #666;
        }
        .itemContent {
          width: 80%;
        }

        ul {
          display: flex;
          align-items: s;
          flex-wrap: wrap;
          width: 100%;
          li {
            font-size: 18px;
            padding: 5px 20px;
            border-radius: 5px;
            border: 1px solid #e7ecf0;
            box-sizing: border-box;
            margin-right: 20px;
            margin-bottom: 20px;
          }
          li:hover {
            cursor: pointer;
            border: 1px solid #fa8919 !important;
            color: #fa8919;
          }
          .selectActive {
            border: 1px solid #fa8919 !important;
            color: #fa8919;
          }
        }
        // 选择支付方式
        .zhiFuItem {
          display: flex;
          /* 选择支付方式 */
          .change-paytype {
            position: relative;
            display: flex;
            align-items: center;
            width: 207px;
            height: 58px;
            margin-right: 10px;
            border: 1px solid #e7ecf0;
            border-radius: 4px;
            cursor: pointer;
          }

          .change-paytype.current {
            border-color: #fa8919;
            color: #fa8919;
          }

          .change-paytype.current:after {
            content: '';
            display: block;
            position: absolute;
            right: -1px;
            bottom: -1px;
            width: 28px;
            height: 28px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAOKADAAQAAAABAAAAOAAAAAANV2hTAAADnUlEQVRoBd3ZzUsUYRwH8O/zzK6llaZkm5mkWdEhN4KEgqKkDhpU9KJpNy/hKch/QK9BdRM7SAQeSnujDgUWXqKgyBBqISKDlswwETXzbZ15eubRGbZ1dp3dmdndmQd255nnZef34Tf7G2UBD7fIta3txKs+FcfA2jwJ1HBq8jwHjMZ5DhiL8xTQCOcZYDycJ4CJcK4HroZzNdAMzrVAszhXApPBuQ6YLM5VwFRwrgGminMF0Aou64FWcVkNtAOXtUC7cFkJtBOXdUC7cVkFdAKXNUCncFkBdBKXcaDTuIwC04HLGDBduIwA04lLOzDduLQCM4FLGzBTuLQAM4lTgZL65lRzGkerWyCduQX41oL9eGvIcOzXJcdxNe2Qqi8LFPv7G4sd+wyBPsNRi4OO4giFVHsdtKpRj1IZ7Nb7sR3bM+gojvohne4E3X1Sd8ivb0Dhr3jNVqCjOH8upLO3QcuPCgtjDEp/G5SBrng2MW4b0BRuTQEwP5kwIMNJvk+60A1aekBMM0WG/LwVLHTfcHn0oC1V1AxOqrsJ6VQnSH4p2NcX0TEk7q8rhu9iL2jJUhFhi/OQn7aAfX6SeN/yrOUiYwanXotsPwxCCEiwiZ9QkQGAJQ4yfxvH9YAUVoh1bGEG8uNmsO+vEu+LmrWUQbM49Xpsbgp0V624NAnsBdlQwjPZFxVKTLdoJ3xND0EKysQEm5uA3NsU93kXs1s/TRmYDE5cbTQEzIyDVh4XpyRQBbI+ADa08nYlgSB8jb18frNYy6ZHsXivHhj9qAdutpMSMGnccjTs1yAwNwm6o0aMkC1BIK8Y7NtLPV5SdhBS/V2Q3I1ijE2EOe48MD6kr0mmkzQwVZwWFBv5wCvpH9CKY2JIFI/cIo7sB6k8AencHZCcPDHHxr4s4aaGte1JH5MCWsVp0bGfA0BkRn+m0ZL9IPxFD10BkXLEMmVkEHJPA7+tx7RtKR1NA+3CaVGy4fcAL/m0/IgYUisl4dVVbUr4DeQHl3imp8S5lTdTQLtxWsBs+B0gR0D5I0RrCq+s8qNmjp/VhiwdVwU6hdOiFv/mRGZBNu2B8qkHyrOrPIURbdryMeGfak7jLEe/ygdw3PTSTW+w0O04lcQIwoZAL+BUIGG0b8Ut6hkc/yb7/P7gfxn0Ck5kj5BO0hoO6UCP4fql8sJWFSqAXsHx75tCCenwlRfWkYbQgsik23Hqo0CtlmpB8fmlLvW2VGFa+wcOncY5YWRXPQAAAABJRU5ErkJggg==)
              no-repeat;
            background-size: 28px 28px;
          }

          .change-paytype-icon {
            margin: 0 11px 0 15px;
          }

          .paytype-icon {
            display: block;
            width: 24px;
            height: 24px;
          }

          .change-paytype-info {
            position: relative;
            flex: 1;
          }
          .change-paytype:hover {
            border-color: #fa8919;
          }
          .change-paytype-label:hover {
            color: #fa8919;
          }
        }
        // 价格
        .price-info {
          width: 80%;
          display: flex;
          align-items: self-end;
          flex-wrap: wrap;
          color: #ff4302;
          margin-top: 20px;
          .sale-price {
            display: flex;
            align-items: self-end;
          }
          .price-icon {
            font-size: 30px;
            font-weight: 400;
            margin-right: 10px;
          }
          .price-val {
            font-size: 45px;
            font-weight: bold;
          }
          .price-old {
            color: #a1a1a1;
            font-size: 25px;
            margin-left: 20px;
          }
        }
      }
      .shop-cart {
        padding: 0 30px;
        height: 60px;
        color: #fff;
        font-size: 30px;
        background-color: #fa8919;
        margin-top: 35px;
        position: absolute;
        bottom: 20px;
      }
    }
  }
  .good-info-item {
    background-color: #fff;
    padding: 30px 40px 50px;
    .good-item-list {
      .good-item-tit {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #666;
        margin-bottom: 35px;
      }
      .good-item-line {
        display: inline-block;
        width: 6px;
        height: 35px;
        background-color: #fa8919;
        margin-right: 15px;
      }
    }
  }
}
// @media screen and (min-width: 320px) and (max-width: 420px)
@media screen and (min-width: 320px) and (max-width: 750px) {
  .page-content {
    width: 100%;
  }
  .good-item-tit {
    font-size: 20px !important;
  }
  .good-info-item {
    padding: 20px !important;
  }
  .good-item-line {
    height: 20px !important;
  }
  .good-info {
    padding: 0 !important;
    margin-top: -10px !important;
    flex-wrap: wrap !important;
    margin-bottom: 15px !important;
    .carousel-box {
      width: 100% !important;
      height: auto !important;
    }
    .content {
      width: 90% !important;
      margin-top: 0;
      margin-left: 20px !important;
      .good-tit {
        margin-top: 6px !important;
      }
      .itemTitle {
        width: 20% !important;
      }
      .zhiFuItem {
        width: 80%;
      }
    }

    .price-val {
      font-size: 30px !important;
    }
    .shop-cart {
      height: 38px !important;
      font-size: 16px !important;
      margin-bottom: 45px;
      position: relative !important;
    }
  }
}
</style>
<style lang="scss">
.itemContent {
  .el-badge__content {
    background-color: #e23b3a;
    border-radius: 12px;
    top: -10px;
  }
  .el-badge__content.is-fixed {
    top: -7px;
    right: -3px;
  }
}
</style>
